import React from 'react';
import {BCLB_NUMBER, BRAND, COMPANY_NAME, SOCIAL_MEDIA} from "../../../../Helper";
import VerifiedIcon from '@mui/icons-material/Verified';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';

export default class Footer extends React.Component {

    userAgreement = () => {
        Event.emit('show_agreement');
    }

    privacyPolicy = () => {
        Event.emit('show_privacy');
    }

    verify = () => {
        Event.emit('show_verify');
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <footer style={{margin: '0 12px'}}>
                    <hr className={"mt-1 mb-1"}/>
                    <div style={{fontSize: '8px'}} className={"text-center text-white"}>
                        {BRAND} is Trademark of {COMPANY_NAME}. {COMPANY_NAME} is authorized and regulated by Betting Control and Licencing Board of Kenya (BCLB) under the betting, lotteries and gaming Act, Cap 131 under Licence No. {BCLB_NUMBER}
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 text-center bet-area"}>
                            <span style={{marginRight: 'auto', alignItems: 'center'}}><VerifiedIcon color={'success'} />&nbsp;<span>Provably Fair</span></span>
                            <a className={"p-1"} href={SOCIAL_MEDIA['wa']} target={"_blank"}><WhatsAppIcon fontSize={'large'} style={{color:'#04A784'}}/></a>
                            <a className={"p-1"} href={SOCIAL_MEDIA['tl']} target={"_blank"}><TelegramIcon fontSize={'large'} style={{color:'#28A7E8'}}/></a>
                            <a className={"p-1"} href={SOCIAL_MEDIA['fb']} target={"_blank"}><FacebookIcon fontSize={'large'} style={{color: '#0B88EF'}}/></a>
                            <a className={"p-1"} href={SOCIAL_MEDIA['tw']} target={"_blank"}><TwitterIcon fontSize={'large'} style={{color: '#188CD8'}}/></a>
                            <a className={"p-1"} href={SOCIAL_MEDIA['in']} target={"_blank"}><InstagramIcon fontSize={'large'} style={{color: '#C52480'}}/></a>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}